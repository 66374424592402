
import DescriptionAdvantage from "../../components/descriptionAdvantage";
import { useTheme } from "../../context/ThemeContext";
import "./css/proDescriptionSection.css";

const ProDescriptionSection = () => {
    const { isDarkMode } = useTheme();

    return (
        <div className={isDarkMode ? "dark-background content-pro dark-text" : "content-pro"}>
            <h2 className="gap-64">Une application pour les professionnels du tatouage</h2>
            <DescriptionAdvantage />

        </div>
    )
};

export default ProDescriptionSection;