import { useEffect } from "react";
import ScrollToHashElement from "../components/scrollToHasElement";
import AppSection from "../sections/appSection/appSection";
import BlogSection from "../sections/blogSection/blogSection";
import DetailSection from "../sections/detailSection/detailSection";
import DownloadSection from "../sections/downloadSection/downloadSection";
import FaqSection from "../sections/faqSection/faqSection";
import Footer from "../sections/footer/footer";
import Header from "../sections/header/header";
import ProSection from "../sections/proSection/proSection";

const Home = () => {
  useEffect(() => {
    document.title = "Accueil | CapInk";
    window.scrollTo(0, 0);
  }
    , []);
  return (
    <>
      <ScrollToHashElement />
      <Header />
      <DownloadSection />
      <ProSection />
      <AppSection />
      <DetailSection />
      <BlogSection />
      <FaqSection />
      <Footer />
    </>
  )
};

export default Home;