
import { useTheme } from "../../context/ThemeContext";
import "./css/proDashboardSection.css";
import "./css/proDashboardSectionMobile.css";
import ImageDashboard from "../../assets/images/imageDashboard.png";
import DashboardMobile from "../../assets/images/DashboardMobile.png";
import ReservationImage from "../../assets/images/ReservationExemple.png";
import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from "react-responsive";



const ProDashboardSection = () => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const isMobile = useMediaQuery({ maxWidth: 1210 });

    const { isDarkMode } = useTheme();
    const settings = {
        dots: true,
        infinite: true,
        fade: true,
        waitForAnimate: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: isDarkMode ? "dark-background" : "",
        beforeChange: (current, next) => setCurrentSlide(next),
        customPaging: i => (
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundColor: i === currentSlide ? "#e491a3" : "#e4e4e4",
                borderRadius: "100px",
              }}
            >

            </div>   )
    };

    return (
        <>
            <div className={isDarkMode ? "dark-background content-dashboard dark-text" : "content-dashboard"}>
                <h2>Un dashboard intégré</h2>
                <p className={isDarkMode ? "center-text dark-text" : "center-text"}>Capink c’est aussi un dashboard dédié aux professionnels du tatouage pour accéder facilement aux informations liées à votre compte.</p>
            </div>
            <div >
                <Slider {...settings}>
                    {!isMobile 
                    ? <div className="contenant-image">
                    <img src={ImageDashboard} alt="Slide 2" className="image-slide" />
                    </div>
                    : null
                    }
                    <div className="contenant-image">
                        <img src={DashboardMobile} alt="Slide 1" className="image-slide" />
                    </div>
                    <div className="contenant-image">
                        <img src={ReservationImage} alt="Slide 1" className="image-slide" />
                    </div>
                    
                </Slider>
            </div>
        </>
    )
};

export default ProDashboardSection;