import { useTheme } from "../context/ThemeContext";

const DescriptionAdvantage = () => {

    const { isDarkMode } = useTheme();

    return (
        <>
            <div className="case-content-one ">
                <div className={isDarkMode ? "case1 case dark-background-case" : "case1 case"}>
                    <h2>+ de 1000</h2>
                    <p className={isDarkMode ? "dark-text" : ""}>Clients potentiels</p>
                </div>
                <div className={isDarkMode ? "case2 case dark-background-case" : "case2 case"}>
                    <h2>10x</h2>
                    <p className={isDarkMode ? "dark-text" : ""}>Plus rapide</p>
                </div>
                <div className={isDarkMode ? "case3 case dark-background-case" : "case3 case"}>
                    <h2>4x</h2>
                    <p className={isDarkMode ? "dark-text" : ""}>Plus de visibilité</p>
                </div>
            </div>
            <div className="case-content-two">
                <div className={isDarkMode ? "case4 case dark-background-case" : "case4 case"}>
                    <h2>100x</h2>
                    <p className={isDarkMode ? "dark-text" : ""}>Plus sécurisé</p>
                </div>
                <div className={isDarkMode ? "case5 case dark-background-case" : "case5 case"}>
                    <h2>1</h2>
                    <p className={isDarkMode ? "dark-text" : ""}>Support client dédié</p>
                </div>
                <div className={isDarkMode ? "case6 case dark-background-case" : "case6 case"}>
                    <h2>-10 %</h2>
                    <p className={isDarkMode ? "dark-text" : ""}>Avec nos partenaires</p>
                </div>
            </div>
        </>
    )
};

export default DescriptionAdvantage;

