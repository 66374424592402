import Header from "../sections/header/header";
import Footer from "../sections/footer/footer";
import ErrorSection from "../sections/errorSection/errorSection";
import { useEffect } from "react";

const ErrorPage = () => {
    useEffect(() => {
      document.title = "Page d'erreur | CapInk";
      window.scrollTo(0, 0);
    }
    , []);
    return (
      <div id="error-page">
      <Header />
       <ErrorSection />
       <Footer />
      </div>
    )
  };
  
  export default ErrorPage;