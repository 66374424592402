
import PinkButton from "../../components/buttons/PinkButton";
import WhiteButton from "../../components/buttons/whiteButton";
import DescriptionAdvantage from "../../components/descriptionAdvantage";
import { useTheme } from "../../context/ThemeContext";
import "./css/proSection.css";
import "./css/proSectionMobile.css"

const ProSection = () => {
    const { isDarkMode } = useTheme();

    return (
        <div className={isDarkMode ? "dark-background content-pro dark-text" : "content-pro"}>
            <h3 className={isDarkMode ? "dark-text" : ""}>gagnez en visibilité !</h3>
            <h2>Vous êtes un professionnel ?</h2>
            <div className="content-button">
                <WhiteButton link="/accueil-tatoueur" styleprop="" text="Voir notre offre pour professionnel" minWidth="280px" />
                <PinkButton link="/en-construction" styleprop="" text="Ajouter mon établissement" minWidth="230px" />
            </div>
            <DescriptionAdvantage />

        </div>
    )
};

export default ProSection;