import { useEffect } from "react";
import BlogSection from "../sections/blogSection/blogSection";
import FaqSection from "../sections/faqSection/faqSection";
import Footer from "../sections/footer/footer";
import Header from "../sections/header/header";
import OfferSection from "../sections/offerSection/offerSection";
import ProDescriptionSection from "../sections/proDescriptionSection/proDescriptionSection";
import ProAppSection from "../sections/proAppSection/proAppSection";
import ProDashboardSection from "../sections/proDashboardSection/proDashboardSection";
import ProResumeSection from "../sections/proResumeSection/proResumeSection";
import ScrollToHashElement from "../components/scrollToHasElement";

const HomeTattoo = () => {
  useEffect(() => {
    document.title = "Offre professionnelle | CapInk";
    window.scrollTo(0, 0);
  }
  , []);
    return (
      <>
      <ScrollToHashElement />
       <Header />
       <OfferSection />
       <ProDescriptionSection />
       <ProAppSection />
       <ProDashboardSection />
       <ProResumeSection />
       <BlogSection />
       <FaqSection />
       <Footer />
      </>
    )
  };
  
  export default HomeTattoo;