
import { useTheme } from "../../context/ThemeContext";
import "./css/proResumeSection.css";
import "./css/proResumeSectionMobile.css";
import PinkButton from "../../components/buttons/PinkButton";
import CheckIcon from '@mui/icons-material/Check';
import Mockup from "../../assets/images/mockupCapink.png";

const ProResumeSection = () => {
    const { isDarkMode } = useTheme();

    return (

        <div className={isDarkMode ? "content-resume dark-background" : "content-resume"}>
            <div className="fiche-produit">
                <div className="title-fiche-produit">
                    <div className="first-line-fiche-produit">
                        <div className="lobster pink size-produit">20,00€ </div>
                        <div className="lobster size-produit">/ mois</div>
                    </div>
                    <div><h3 className="size-offre">Offre professionnelle</h3></div>
                </div>
                <div className="content-fiche-produit">
                    <div className="element-fiche"><CheckIcon color="success" /><b>Codes promo</b> matériel</div>
                    <div className="element-fiche"><CheckIcon color="success" />Gérez vos rendez-vous</div>
                    <div className="element-fiche"><CheckIcon color="success" />Paiement facilité</div>
                    <div className="element-fiche"><CheckIcon color="success" />Sans engagement</div>
                </div>
                <PinkButton text="Je m'abonne" />
            </div>

            <div><img src={Mockup} alt="exemple application" className="image-mockup"/></div>
        </div>


    )
};

export default ProResumeSection;