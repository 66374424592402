import Header from "../sections/header/header";
import Footer from "../sections/footer/footer";
import ConstructionSection from "../sections/constructionSection/constructionSection";
import { useEffect } from "react";

const ConstructionPage = () => {
  useEffect(() => {
    document.title = "Page en construction | CapInk";
    window.scrollTo(0, 0);
  }
  , []);
    return (
      <div>
      <Header />
       <ConstructionSection/>
       <Footer />
      </div>
    )
  };
  
  export default ConstructionPage;