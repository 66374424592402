import './Common.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import HomeTattoo from "./pages/HomeTattoo";
import { ThemeProvider } from './context/ThemeContext';
import ErrorPage from './pages/ErrorPage';
import React from 'react';
import ConstructionPage from './pages/ConstructionPage';
import EspacePro from './pages/EspaceProPage';

function App() {
  const router = createBrowserRouter([

    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/accueil-tatoueur",
      element: <HomeTattoo />,
      errorElement: <ErrorPage />,
    },
    {
      path: "*",
      element: <ErrorPage />,
    },
    {
      path: "/en-construction",
      element: <ConstructionPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/espace-pro",
      element: <EspacePro />,
      errorElement: <ErrorPage />,
    },
  ]);

  return (
    <ThemeProvider>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </ThemeProvider>
  );
}

export default App;

