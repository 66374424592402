
import { useTheme } from "../../context/ThemeContext";
import "./css/downloadSection.css";
import "./css/downloadSectionMobile.css";
import image from "../../assets/images/sampleApplication.png";
import GoogleStore from "../../assets/svg/GoogleStore.svg";
import AppleStore from "../../assets/svg/AppleStore.svg";
import DarkGoogleStore from "../../assets/svg/DarkGoogleStore.svg";
import DarkAppleStore from "../../assets/svg/DarkAppleStore.svg";
import { Link } from "react-router-dom";
import PinkButton from "../../components/buttons/PinkButton";

const DownloadSection = () => {

  const { isDarkMode } = useTheme();

  return (
    <div className={isDarkMode ? "dark-background" : ""}>
      <div className="download-content">
        <div className="info">
          <h1 className={isDarkMode ? "dark-text" : ""}>Une application Ink'royable !</h1>
          <p className={isDarkMode ? "dark-text" : ""}>Prêt à trouver votre prochain tatoueur ? Enfin une application faite pour vous !<br /></p>
          <div className="download-button">
            {/* <Link to="/en-construction"><img src={isDarkMode ? DarkGoogleStore : GoogleStore} alt="Google Store" className="button" /></Link>
            <Link to="/en-construction"><img src={isDarkMode ? DarkAppleStore : AppleStore} alt="Apple Store" className="button" /></Link> */}
            <PinkButton link="https://airtable.com/appAreq3j3z61cdjm/shrrIJ7vqwDrdnYgf" text="M'alerter du lancement" minWidth="190px" />
          </div>
        </div>
        <div><img src={image} alt="exemple de l'application" className="image" /></div>
      </div>
    </div>
  )

};

export default DownloadSection;