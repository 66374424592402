import { Link } from "react-router-dom";

const PinkButton = (props) => {
    return <Link to={props.link} style={{ ...styles.pink, width: props.minWidth }} className={props.styleprop}>{props.text}</Link>
  };
  
  export default PinkButton;

  const styles = {
    pink: {
      display: "inherit",
      padding: "10px 24px",
      backgroundColor: "var(--primary-dark)",
      borderRadius: "10px",
      fontFamily: 'Poppins-semibold',
      color: "var(--titre-dark)",
      textDecoration: "none",
      textAlign: "center",
    }
  }