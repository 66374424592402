
import { useTheme } from "../../context/ThemeContext";
import "./css/faqSection.css";
import "./css/faqSectionMobile.css";
import Accordion from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Baguette from "../../assets/svg/Baguette.svg";
import Pointer from "../../assets/svg/Pointer.svg";
import Cerveau from "../../assets/svg/Cerveau.svg";

const FaqSection = () => {
    const { isDarkMode } = useTheme();

    return (
        <div id="faq" className={isDarkMode ? "dark-background content-faq dark-text" : "content-faq"}>
            <h3 className={isDarkMode ? "dark-text" : ""}>Trouvez les réponses ici !</h3>
            <h2>Foire aux questions</h2>
            <div className="faq-bloc">
                <Accordion
                    slotProps={{ transition: { timeout: 400 } }}
                    style={{ "background": "none", "boxShadow": "0px 1px 0px 0px rgba(255, 255, 255, 0.87)" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={isDarkMode ? "dark-text" : ""} />}
                        className="title-faq">
                        <div className={isDarkMode ? "title-faq dark-text" : "title-faq light-text"}><img src={Baguette} className={isDarkMode ? "image-faq-dark" : "image-faq"} alt="baguette icon"/>L'utilisation de l'application sera-t-elle payante ?</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={isDarkMode ? "description-faq dark-text" : "description-faq light-text"}>Non, l'application est gratuite pour les personnes cherchant un tatoueur.</div>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    slotProps={{ transition: { timeout: 400 } }}
                    style={{ "background": "none", "boxShadow": "0px 1px 0px 0px rgba(255, 255, 255, 0.87)" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={isDarkMode ? "dark-text" : ""} />}
                        className="title-faq">
                        <div className={isDarkMode ? "title-faq dark-text" : "title-faq light-text"}><img src={Pointer} className={isDarkMode ? "image-faq-dark" : "image-faq"} alt="pointer-icon"/>Quand l'application sera-t-elle disponible ?</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={isDarkMode ? "description-faq dark-text" : "description-faq light-text"}>L'application est en cours de développement et sera disponible dans les prochains mois.</div>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    slotProps={{ transition: { timeout: 400 } }}
                    style={{ "background": "none", "boxShadow": "0px 1px 0px 0px rgba(255, 255, 255, 0.87)" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={isDarkMode ? "dark-text" : ""} />}
                        className="title-faq">
                        <div className={isDarkMode ? "title-faq dark-text" : "title-faq light-text"}><img src={Cerveau} className={isDarkMode ? "image-faq-dark" : "image-faq"} alt="brain icon"/>Comment sont référencés les tatoueurs ?</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={isDarkMode ? "description-faq dark-text" : "description-faq light-text"}>
                            En créant un compte professionnel sur notre application, votre profil tatoueur sera <b>visible dans toute la France</b> et ainsi vous apporter <b>plus de visibilité</b>.
                            <br /><br />
                            Vous aurez la possibilité d’afficher toutes vos réalisations, vos préférences de styles de tatouage, votre localisation, une présentation de vous, tout cela directement sur votre profil tatoueur accessible à toute personne souhaintant se faire tatouer.  </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
};

export default FaqSection;