
import PinkButton from "../../components/buttons/PinkButton";
import { useTheme } from "../../context/ThemeContext";
import "./css/proAppSection.css";
import MockUp from "../../assets/images/mockupBlackCapink.png";

const ProAppSection = () => {

    const { isDarkMode } = useTheme();

    return (
        <div className={isDarkMode ? "dark-background content-app dark-text dark-image-background" : "content-app light-image-background"}>
            <div><img src={MockUp} alt="Application screen" /></div>
            <div className="text-content">
                <h3 className={isDarkMode ? "dark-text" : ""}>Une meilleur visibilité</h3>
                <h2 >Une application dédiée à l'univers du tatouage</h2>
                <p className={isDarkMode ? "dark-text text gap-32" : "text gap-32"}>Se référencer en tant que tatoueur peut parfois sembler être une tâche difficile. Mais grâce à CapInk, nous avons rendu le référencement et l’accessibilité simple et efficace. Que vous soyez un tatoueur expérimenté ou un débutant !</p>
                <PinkButton link="https://airtable.com/appAreq3j3z61cdjm/shrrIJ7vqwDrdnYgf" text="M'alerter du lancement" minWidth="190px" />
            </div>
        </div>
    )
};

export default ProAppSection;