
import PinkButton from "../../components/buttons/PinkButton";
import { useTheme } from "../../context/ThemeContext";
import "./css/appSection.css";
import "./css/appSectionMobile.css";
import MockUp from "../../assets/images/mockupBlackCapink.png";
import { useMediaQuery } from "react-responsive";

const AppSection = () => {

    const { isDarkMode } = useTheme();
    const isMobile = useMediaQuery({ maxWidth: 1610 });

    return (
        <div className={isDarkMode ? "dark-background content-app dark-text dark-image-background" : "content-app light-image-background"}>
            <div><img src={MockUp} alt="Application screen" /></div>
            <div className="text-content">
                <h3 className={isDarkMode ? "dark-text" : ""}>Des tatoueurs certifiés</h3>
                <h2>Une application dédiée à l'univers du tatouage</h2>
                <p className={`${isDarkMode ? "dark-text" : "text"} ${isMobile ? 'gap-64' : ''}`}>Découvrez bientôt notre application qui facilitera votre quête du tatoueur idéal et vous inspirera avec une collection captivante de motifs de tatouage. Restez informé pour être parmi les premiers à l'explorer dès son lancement !</p>
                <PinkButton link="https://airtable.com/appAreq3j3z61cdjm/shrrIJ7vqwDrdnYgf" text="M'alerter du lancement" minWidth="190px" />
            </div>
        </div>
    )
};

export default AppSection;