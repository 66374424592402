
import { useTheme } from "../../context/ThemeContext";
import "./css/detailSection.css";
import "./css/detailSectionMobile.css";
import AppPhoto from "../../assets/images/appPhoto.png";
import ChatPhoto from "../../assets/images/ChatPhoto.png";
import LoupePhoto from "../../assets/images/LoupePhoto.png";
import TattooPhoto from "../../assets/images/TattooPhoto.png";
import SmileIcon from "../../assets/svg/SmileIcon.svg";
import PointIcon from "../../assets/svg/PointIcon.svg";
import ClickIcon from "../../assets/svg/ClickIcon.svg";

const DetailSection = () => {

    const { isDarkMode } = useTheme();

    return (
        <div id="detail" className={isDarkMode ? "dark-background content-detail dark-text" : "content-detail"}>
            <h3 className={isDarkMode ? 'dark-text' : ''}>Avec CapInk, c'est simple et efficace !</h3>
            <h2>Vous cherchez à vous faire tatouer ?</h2>
            <div className="bloc-content">
                <div className="bloc">
                    <div className="bloc-title">
                        <div className={isDarkMode ? "number-bloc-dark" : "number-bloc"}>1</div><p className={isDarkMode ? 'dark-text text' : 'text'}>Télécharge l'application et inscris-toi !</p>
                    </div>
                    <div><img src={AppPhoto} alt='application'/></div>
                </div>
                <div className="bloc">
                    <div className="bloc-title">
                        <div className={isDarkMode ? "number-bloc-dark" : "number-bloc"}>2</div><p className={isDarkMode ? 'dark-text text' : 'text'}>Recherche un tatoueur avec nos filtres</p>
                    </div>
                    <div><img src={LoupePhoto} alt='loupe'/></div>
                </div>
                <div className="bloc">
                    <div className="bloc-title">
                        <div className={isDarkMode ? "number-bloc-dark" : "number-bloc"}>3</div><p className={isDarkMode ? 'dark-text text' : 'text'}>Contacte-le et explique-lui ton projet</p>
                    </div>
                    <div><img src={ChatPhoto} alt='chat'/></div>
                </div>
                <div className="bloc">
                    <div className="bloc-title">
                        <div className={isDarkMode ? "number-bloc-dark" : "number-bloc"}>4</div><p className={isDarkMode ? 'dark-text text' : 'text'}>Prends rendez-vous avec lui et hop ! C'est fini !</p>
                    </div>
                    <div><img src={TattooPhoto} alt='tatouage'/></div>
                </div>
            </div>
            <div className={isDarkMode ? "bloc-info-dark" : "bloc-info"}>
                <div className="info">
                    <img src={SmileIcon} className="icon-info" alt='icon info'/>
                    <p className={isDarkMode ? "detail-title-dark dark-text" : "detail-title"}>Tatoueurs certifiés</p>
                    <p className={isDarkMode ? "detail-text dark-text" : "detail-text"}>Nos artistes respectent des normes d'hygiène strictes et détiennent des certifications et diplômes reconnus.</p>
                </div>
                <div className="info">
                    <img src={ClickIcon} className="icon-info" alt='icon info'/>
                    <p className={isDarkMode ? "detail-title-dark dark-text" : "detail-title"}>Facile d'utilisation</p>
                    <p className={isDarkMode ? "detail-text dark-text" : "detail-text"}>Une expérience simple et agréable vous attend ! Affinez votre recherche grâce à des filtres pour trouver rapidement votre tatoueur.</p>
                </div>
                <div className="info">
                    <img src={PointIcon} className="icon-info" alt='icon info'/>
                    <p className={isDarkMode ? "detail-title-dark dark-text" : "detail-title"}>Et bien plus encore...</p>
                    <p className={isDarkMode ? "detail-text dark-text" : "detail-text"}>Publiez vos projets, découvrez les derniers flashs de vos tatoueurs.ses préféré.es et recevez des notifications sur les guests proches de chez vous !</p>
                </div>
            </div>

        </div>
    )
};

export default DetailSection;