import { Link } from "react-router-dom";

const WhiteButton = (props) => {
    return <Link to={props.link} style={{ ...styles.white, minWidth: props.minWidth }} className={props.styleprop}>{props.text}</Link>
  };
  
  export default WhiteButton;

  const styles = {
    white: {
      display: "inherit",
        padding: "10px 24px",
        borderRadius: "10px",
        border: "1px solid var(--primary-dark)",
        color: "var(--primary-dark)",
        fontFamily: 'Poppins-semibold',
        textDecoration: "none",
        textAlign: "center",
    }
  }