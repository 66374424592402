
import { useTheme } from "../../context/ThemeContext";
import "./css/offerSection.css";
import "./css/offerSectionMobile.css";
import { Breadcrumbs, Link } from "@mui/material";
import PinkButton from "../../components/buttons/PinkButton";
import CheckIcon from '@mui/icons-material/Check';

const OfferSection = () => {

    const { isDarkMode } = useTheme();

    return (
        <div className="content-offer">
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
                <Link underline="hover" color="var(--primary-dark)" href="/">
                    &lt; Accueil
                </Link>
                <Link underline="hover" color="white" href="/accueil-tatoueur">
                    Offre tatoueur.se
                </Link>
            </Breadcrumbs>
            <div className="bloc-offer">
                <h1 className="h1-lobster">Offre tatoueur.se</h1>
                <h3 className="dark-text">Gagnez du temps et augmentez votre productivité grâce à CapInk</h3>
                <div className="fiche-produit">
                    <div className="title-fiche-produit">
                        <div className="first-line-fiche-produit">
                            <div className="lobster pink size-produit">20,00€ </div>
                            <div className="lobster size-produit">/ mois</div>
                        </div>
                        <div><h3 className="size-offre">Offre professionnelle</h3></div>
                    </div>
                    <div className="content-fiche-produit">
                        <div className="element-fiche"><CheckIcon color="success"/><b>Codes promo</b> matériel</div>
                        <div className="element-fiche"><CheckIcon color="success"/>Gérez vos rendez-vous</div>
                        <div className="element-fiche"><CheckIcon color="success"/>Paiement facilité</div>
                        <div className="element-fiche"><CheckIcon color="success"/>Sans engagement</div>
                    </div>
                    <PinkButton text="Je m'abonne" />
                </div>
            </div>


        </div>
    )
};

export default OfferSection;