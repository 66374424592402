
import "./css/errorSection.css";
import "./css/errorSectionMobile.css";
import PinkButton from "../../components/buttons/PinkButton";
import { Breadcrumbs, Link } from "@mui/material";
import Patrick from "../../assets/images/patrickManger.png";

const ErrorSection = () => {


    return (
        <div className="gray-background whole-error">
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
                <Link underline="hover" color="var(--primary-dark)" href="/">
                &lt; Accueil
                </Link>
                <Link underline="hover" color="white" href="*">
                    La page n'existe pas
                </Link>
            </Breadcrumbs>
            <div id="blog" className="gray-background content-error dark-text">
                <div className="lobster-title gap-32">Oups !</div>
                <h3 className="gap-64 dark-text center-text">Patrick a été trop gourmand,<br />Il a mangé la page !</h3>
                <PinkButton text="Retour à l'accueil" link="/" />
                <div className="patrick-manger"><img src={Patrick} alt="Patrick" className="patrick-image-manger" /></div>
            </div>
        </div>
    )

};

export default ErrorSection;