import { useEffect } from "react";
import ScrollToHashElement from "../components/scrollToHasElement";
import Footer from "../sections/footer/footer";
import Header from "../sections/header/header";
import LoginEspaceProSection from "../sections/LoginEspaceProSection/LoginEspaceProSection";

const EspacePro = () => {
  useEffect(() => {
    document.title = "Espace Pro | CapInk";
    window.scrollTo(0, 0);
  }
    , []);
  return (
    <>
      <ScrollToHashElement />
      <Header />
      <LoginEspaceProSection />
      <Footer />
    </>
  )
};

export default EspacePro;